class Quiz {
  constructor(container) {
    this.container = container;
    this.options = {
      contactFormSent: false,
    };
    this.state = {
      quizId: null,
      questions: [],
      contactFormSent: false,
    };
    this.initSlides();
    // this.nextSlide();
  }

  getState() {
    return this.state;
  }

  setState(state) {
    this.state = state;
  }

  getStateQuestionsIds() {
    return this.state.questions.map(question => question.questionId);
  }

  getStateAnswers() {
    const stateAnswers = [];
    this.state.questions.forEach((question) => {
      question.answers.forEach(answerId => stateAnswers.push(answerId));
    });
    return stateAnswers;
  }

  pushStateQuestion(question) {
    this.state.questions.push(question);
  }

  setStateQuizId(quizId) {
    this.state.quizId = quizId;
  }

  setStateContactForm(bool) {
    this.state.contactForm = bool;
  }

  getStateContactForm() {
    return this.state.contactForm;
  }

  initSlides() {
    // - initSlides() - обходим все формы в слайдах в квизе, чтобы обрабатывать пользовательский ввод и подписываемся
    // - click `[data-quiz-branch-select]` -> `onSelectBranch(branchId)`
    // - submit `[data-question-id] form` -> `onSlideSubmit(formEl)`
    // - submit `[data-quiz-contact-form]` -> `onSubmitContactForm(formEl)`
    const quizId = parseInt(this.container.dataset.quiz, 10);
    this.setStateQuizId(quizId);

    this.nextSlide();

    const questionForms = this.container.querySelectorAll('[data-question-id] form');
    questionForms.forEach((questionForm) => {
      const prevButton = questionForm.querySelector('[data-prev-button]');
      if (prevButton) {
        prevButton.addEventListener('click', (e) => {
          e.preventDefault();
          this.prevSlide(e.target);
        });
      }
      questionForm.addEventListener('submit', (e) => {
        e.preventDefault();
        this.onSlideSubmit(questionForm);
      });
    });

    // const form = this.container.querySelector('[data-quiz-contact-form]');
    // form.addEventListener('submit', (e) => {
    //   e.preventDefault();
    //   this.onSubmitContactForm(form);
    // });

    document.addEventListener('quiz-form-sent', (e) => {
      this.onSubmitContactForm(e.detail.form);
    });
  }

  onSubmitContactForm(formEl) {
    // радем кусок обработки ajax-form, при успешной отправке формы вызываем `setStateContactForm(true)`, затем `nextSlide()`
    this.setStateContactForm(true);
    this.nextSlide();
  }

  onSlideSubmit(formEl) {
    // - тут валидируем что пользователь выбрал вариант (если таковые ему предоставлены) или заполнил поля (опять же если они есть)
    // - если что-то не верно - показываем это безобразие в `[data-form-error]` этого слайда
    // - если все заполнено верно, сохраняем выбранное значение `pushStateQuestion({questionId: ИД, answers: ОТВЕТЫ, input1: ЗНАЧЕНИЕ_ПОЛЯ_1, input2: ЗНАЧЕНИЕ_ПОЛЯ_2})`
    this.cleanErrors(formEl);
    let hasErrors = false;
    let answersInputs = null;
    const { questionId } = formEl.closest('[data-question-id]').dataset;
    const answersContainer = formEl.querySelector('[data-question-variants]');
    if (answersContainer) {
      answersInputs = answersContainer.querySelectorAll('input');
    }
    const answersDropDown = formEl.querySelector('select');
    const answers = [];
    if (answersInputs) {
      answersInputs.forEach((answersInput) => {
        if (answersInput.checked) {
          answers.push(
            parseInt(answersInput.value, 10),
          );
        }
      });
    }

    if (answersDropDown && answersDropDown.value) {
      answers.push(
        parseInt(answersDropDown.value, 10),
      );
    }

    if (answersInputs && answers.length <= 0) {
      hasErrors = true;
      this.addErrors(formEl, 'Выберите хотя бы один вариант ответа');
    }

    let value1 = null;
    if (formEl.querySelector('textarea[name="input_1"]')) {
      value1 = formEl.querySelector('textarea[name="input_1"]').value;

      if (value1.length <= 0) {
        hasErrors = true;
        this.addErrors(formEl, 'Вы не ответили на вопросы');
      }
    }

    // let value2 = null;
    // if (formEl.querySelector('textarea[name="input_2"]')) {
    //   value2 = formEl.querySelector('textarea[name="input_2"]').value;
    //
    //   if (value2.length <= 0) {
    //     hasErrors = true;
    //     this.addErrors(formEl, 'Вы не ответили на вопросы');
    //   }
    // }

    if (hasErrors) {
      return false;
    }

    this.pushStateQuestion({
      questionId: parseInt(questionId, 10),
      answers,
      input1: value1,
      // input2: value2,
    });

    this.checkOneAnswer();
    this.checkAnswers();
    this.nextSlide();
  }

  nextSlide() {
    this.showSlide(this.findNextSlide());
  }

  prevSlide(prevButton) {
    const questionId = parseInt(prevButton.dataset.prevButton, 10);
    if (this.findPrevQuestionSlide(questionId)) {
      this.showSlide(this.findPrevQuestionSlide(questionId));
    }
  }

  showSlide(element) {
    const slides = this.container.querySelectorAll('.quiz-slide');
    slides.forEach((slide) => {
      slide.style.display = 'none';
    });
    element.style.display = 'flex';
    const progressBarContainer = element.querySelector('[data-quiz-progress-wrap]');
    this.initProgressBar(progressBarContainer);
  }

  findNextSlide() {
    const questionSlide = this.findNextQuestionSlide();
    if (questionSlide) {
      return questionSlide;
    }

    // - если вопросов по такому алгоритму не нашлось - смотрим на contactFormSent
    if (this.getStateContactForm()) {
      // - если contactFormSent=true, то ищем слайд финала (только уже 1) по похожей схеме с поиском слайда вопроса и выдаем его
      const finalSlide = this.findFinalSlide();
      if (finalSlide) {
        return finalSlide;
      }
    }

    // - если contactFormSent=false, то выдаем слайд с формой
    const form = this.container.querySelector('[data-quiz-form]');
    const formAnswerField = form.querySelector('textarea[name="BidForm[answers_data]"]');
    // Записываем JSON.stringify(state) в поле answers_data
    formAnswerField.value = JSON.stringify(this.state);
    return form;
  }

  findFinalSlide() {
    const finalSlides = this.container.querySelectorAll('[data-final-id]');
    let foundFinalSlide = null;
    finalSlides.forEach((slide) => {
      if (foundFinalSlide) {
        return;
      }

      const { slideIf } = slide.dataset;
      const slideIfs = JSON.parse(slideIf);

      if (slideIfs.length <= 0) {
        foundFinalSlide = slide;
        return;
      }

      // intersect slideIfs and this.getStateAnswers()
      const answers = this.getStateAnswers();
      const intersection = slideIfs.filter(value => answers.includes(value));

      // 3. Если у слайда есть data-slide-if - и хотя бы одно значение из data-slide-if есть в `getStateAnswers()`
      if (intersection.length > 0) {
        foundFinalSlide = slide;
      }
    });

    if (foundFinalSlide) {
      return foundFinalSlide;
    }

    return null;
  }

  findNextQuestionSlide() {
    const questionSlides = this.container.querySelectorAll('[data-question-id]');
    let foundQuestionSlide = null;
    questionSlides.forEach((slide) => {
      if (foundQuestionSlide) {
        return;
      }
      const { questionId, slideIf } = slide.dataset;

      const slideIfs = JSON.parse(slideIf);

      const stateQuestions = this.getStateQuestionsIds();
      // 2. Мы не задавали этот вопрос - его ID нет в `getStateQuestions()`
      if (stateQuestions.includes(parseInt(questionId, 10))) {
        return;
      }

      if (slideIfs.length <= 0) {
        foundQuestionSlide = slide;
        return;
      }

      // intersect slideIfs and this.getStateAnswers()
      const answers = this.getStateAnswers();
      const intersection = slideIfs.filter(value => answers.includes(value));

      // 3. Если у слайда есть data-slide-if - и хотя бы одно значение из data-slide-if есть в `getStateAnswers()`
      if (intersection.length > 0) {
        foundQuestionSlide = slide;
        return;
      }
    });

    if (foundQuestionSlide) {
      return foundQuestionSlide;
    }
  }

  findPrevQuestionSlide(questionId) {
    const questions = this.getQuestionSlides();
    const currentSlideIndex = this.getCurrentSlideIndex(questionId);

    if (currentSlideIndex === null) {
      return null;
    }

    if (this.getStateQuestionsIds().includes(questionId)) {
      this.state.questions.splice((this.getStateQuestionsIds().indexOf(questionId) - 1), 2);
    }

    return questions[currentSlideIndex - 1];
  }

  cleanErrors(slideEl) {
    const errorEl = slideEl.querySelector('[data-form-error]');
    errorEl.innerHTML = '';
  }

  addErrors(slideEl, error) {
    const errorEl = slideEl.querySelector('[data-form-error]');
    const div = document.createElement('div');
    div.innerHTML = error;
    errorEl.appendChild(div);
  }

  checkAnswers() {
    const answerElements = this.container.querySelectorAll('[data-answer-show-if]');
    const stateAnswers = this.getStateAnswers();

    answerElements.forEach((answer) => {
      const { answerShowIf } = answer.dataset;

      if (!answerShowIf) {
        return;
      }

      const slideIfs = JSON.parse(answerShowIf);

      if (slideIfs.length <= 0) {
        return;
      }

      slideIfs.forEach((id) => {
        if (stateAnswers.includes(id)) {
          answer.style.display = 'flex';
        } else {
          answer.style.display = 'none';
        }
      });
    });
  }

  checkOneAnswer() {
    const answerElements = this.container.querySelectorAll('[data-answer-show-one-if]');

    if (!answerElements) {
      return;
    }

    const stateAnswers = this.getStateAnswers();
    let found = false;

    answerElements.forEach((answer) => {
      if (found) {
        return;
      }

      const { answerShowOneIf } = answer.dataset;

      if (!answerShowOneIf) {
        return;
      }

      const slideIfs = JSON.parse(answerShowOneIf);

      if (slideIfs.length <= 0) {
        return;
      }

      slideIfs.forEach((id) => {
        if (stateAnswers.includes(id)) {
          answer.style.display = 'flex';
          found = true;
        } else {
          answer.style.display = 'none';
        }
      });
    });
  }

  findCurrentQuestion() {
    const questions = this.getQuestionSlides();

    let currentQuestion = null;

    questions.forEach((question) => {
      const style = question.getAttribute('style');
      if (style === 'display: flex;') {
        currentQuestion = question;
      }
      return null;
    });

    return currentQuestion;
  }

  getQuestionSlides() {
    return this.container.querySelectorAll('.quiz-slide_question');
  }

  initProgressBar(container) {
    if (!container) {
      return;
    }

    const questions = this.getQuestionSlides();
    const questionCount = questions.length + 1; // +1 - это отдельный слайд с формой
    const percentPerQuestion = Math.round(100 / questionCount);
    const progressPercent = container.querySelector('[data-quiz-progress-percent]');
    const progressLine = container.querySelector('[data-quiz-progress-line]');
    let index = this.getCurrentSlideIndex();
    if (index === null) {
      index = 0;
    }
    const currentSlideIndex = index + 1;
    let percent = percentPerQuestion * currentSlideIndex;

    if (container.classList.contains('progress-bar_final')) {
      percent = 100;
    }

    progressPercent.innerHTML = `${percent}%`;
    progressLine.style.width = `${percent}%`;
  }

  getCurrentSlideIndex(questionId) {
    const questions = this.getQuestionSlides();
    let currentSlideIndex = null;
    let currentSlideId = null;

    if (!questionId) {
      if (this.findCurrentQuestion()) {
        currentSlideId = parseInt(this.findCurrentQuestion().dataset.questionId, 10);
      }
    }

    if (questionId) {
      currentSlideId = parseInt(questionId, 10);
    }

    questions.forEach((question, key) => {
      if (parseInt(question.dataset.questionId, 10) === currentSlideId) {
        currentSlideIndex = key;
      }
    });

    return currentSlideIndex;
  }
}

const bind = () => {
  const container = document.querySelector('[data-quiz]');
  if (container) {
    new Quiz(container);
  }
};

document.addEventListener('DOMContentLoaded', bind);
document.addEventListener('modal-opened', bind);