// import '../../../node_modules/swiper/swiper.scss';
import '../../../node_modules/swiper/swiper.min.css';
import { Navigation, Swiper } from 'swiper';


function init() {
  const swipeSliderContainer = document.querySelector('[data-quiz-slider-container]');
  if (!swipeSliderContainer) {
    return;
  }

  const swipeSliderElements = swipeSliderContainer.querySelectorAll('[data-quiz-slider]');
  if (!swipeSliderElements) {
    return;
  }
  const switchers = swipeSliderContainer.querySelectorAll('[data-slider-switcher]');

  swipeSliderElements.forEach((swipeSliderElement) => {
    const answer = swipeSliderElement.dataset.quizSlider;
    const leftArrow = swipeSliderContainer.querySelector(`[data-quiz-slider-left="${answer}"]`);
    const rightArrow = swipeSliderContainer.querySelector(`[data-quiz-slider-right="${answer}"]`);
    const sliderTabs = swipeSliderElement.querySelectorAll('[data-slider-tab]');

    const slider = new Swiper(swipeSliderElement, {
      modules: [Navigation],
      slidesPerView: 'auto',
      navigation: {
        prevEl: leftArrow,
        nextEl: rightArrow,
      },
    });

    sliderTabs.forEach((sliderTab) => {
      sliderTab.addEventListener('click', () => {
        const index = parseInt(sliderTab.dataset.sliderTab, 10);

        slider.slideTo(index);
      });
    });

    slider.on('slideChange', () => {
      sliderTabs.forEach((sliderTab) => {
        sliderTab.classList.remove('_active');
      });

      const tab = swipeSliderElement.querySelector(`[data-slider-tab="${slider.activeIndex}"]`);
      if (tab) {
        tab.classList.add('_active');
      }
    });
  });

  switchers.forEach((switcher) => {
    switcher.addEventListener('click', () => {
      const index = switcher.dataset.sliderSwitcher;

      swipeSliderElements.forEach((swipeSliderElement) => {
        swipeSliderElement.classList.remove('_active');
        swipeSliderElement.style.display = 'none';
      });

      swipeSliderContainer.querySelector(`[data-quiz-slider="${index}"]`).classList.add('_active');
      swipeSliderContainer.querySelector(`[data-quiz-slider="${index}"]`).style.display = 'flex';
    });
  });
}

document.addEventListener('DOMContentLoaded', init);
document.addEventListener('modal-opened', init);